import React from 'react';
import Layout from '../component/layout/layout';
import { pageContainer } from '../styles.css';

type Props = {};

const expo = (props: Props) => {
    return (
        <Layout>
            <div className={pageContainer}>


            </div>
        </Layout>
    );
};

export default expo;